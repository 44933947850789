import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { List } from '@mui/material';
import { ChatbotFAQ, ChatList } from 'src/@types/apiResponseTypes';

import useToggleDrawer from '../../hooks/useToggleDrawer';
import { CurrentChatRoomIdState, OnDeleteChatRoom } from '../../utils/types';

import ChatListItemBox from './ChatListItemBox';

interface ChatsSidebarProps {
  currentChatRoomIdState: CurrentChatRoomIdState;
  chatRoomsCollection: {
    chatRooms: ChatList[];
    innerwaveGuideChatRooms?: ChatbotFAQ[];
  };
  onDeleteChatRoom: OnDeleteChatRoom;
}

function ChatsSidebar({
  currentChatRoomIdState,
  chatRoomsCollection,
  onDeleteChatRoom,
}: ChatsSidebarProps) {
  const { chatRooms, innerwaveGuideChatRooms } = chatRoomsCollection;

  const toggleDrawer = useToggleDrawer();

  return (
    <List sx={{ width: '100%', paddingX: '4px' }}>
      <ChatListItemBox
        subtitle={{
          text: 'CHAT LIST',
          icon: <DeleteOutlineIcon sx={{ color: '#00FF00', fontSize: 18 }} />,
        }}
        chatRooms={chatRooms}
        currentChatRoomIdState={currentChatRoomIdState}
        onCloseDrawer={toggleDrawer.handleDrawerClose}
        onDeleteChatRoom={onDeleteChatRoom}
      />
      <ChatListItemBox
        subtitle={{ text: 'INNERWAVE GUIDE' }}
        chatRooms={innerwaveGuideChatRooms || []}
        currentChatRoomIdState={currentChatRoomIdState}
        onCloseDrawer={toggleDrawer.handleDrawerClose}
      />
    </List>
  );
}

export default ChatsSidebar;
