import { KeyboardEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import OpenAI from 'openai';
import remarkGfm from 'remark-gfm';
import DialogTitleWithCloseButton from 'src/components/dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import * as Yup from 'yup';

import Image from '../../../components/image';

type TFormData = {
  searchKeyword: string;
};
let completion: any;
const OpenAiForm = observer(() => {
  const theme = useTheme();
  const resolver = yupResolver(
    Yup.object().shape({
      searchKeyword: Yup.string().required('검색어를 입력해주세요'),
    }),
  );

  const methods = useForm<TFormData>({
    resolver,
  });

  const { getValues } = methods;

  const openai = new OpenAI({
    organization: 'org-1cEDEOUmLi0gVGYxRRA4tmrx',
    project: 'proj_AkLfFOctdArtHtW2Eci7Se68',
    apiKey: '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',
    dangerouslyAllowBrowser: true,
  });

  const [isLoading, setLoading] = useState(false);
  const [isFinish, setFinish] = useState(false);
  const [title, setTitle] = useState('');
  const [response, setResponse] = useState<any>('');
  const content = useRef<HTMLElement>(null);

  const onSubmit = async (form: TFormData) => {
    try {
      //   console.log('🚀 ~ onSubmit ~ form:', form);
      setLoading(true);
      setFinish(false);
      if (response !== '') {
        setResponse((response: any) => response + '\n\n--- \n\n');
      }

      if (completion && completion.controller) {
        completion.controller.abort();
      }

      completion = await openai.chat.completions.create({
        // model: 'gpt-3.5-turbo',
        stream: true,
        model: 'gpt-4',
        messages: [
          {
            role: 'system',
            content:
              // 당신은 사용자가 웹, 리액트, 리액트 네이티브, 자바, 자바스크립트, 타입스크립트, CSS 등을 개발 하는것을 도와주는 조수이자 가르쳐주는 선생님 입니다. 사용자는 당신에게 궁금한 점 또는 더 좋은 코드나 방법을 물어볼 것이고, 당신은 그들이 문제를 해결할 수 있도록 도와주고 더 좋은 코드, 최신 기술에 맞는 코드를 가르쳐 줄 것입니다. 응답은 단락 형식과 코드를 포함한 형식 이어야 합니다.
              'You are an assistant and teacher who helps users develop the web, react, native, Java, JavaScript, TypeScript, CSS, and more. Users will ask you questions or better codes or methods, and you will help them solve problems and teach them better codes and codes that fit the latest technology. Your responses should be in the form of paragraphs and codes.',
          },
          {
            role: 'user',
            content: form.searchKeyword || '',
          },
        ],
      });

      if (completion) {
        setLoading(false);
      }

      for await (const chunk of completion) {
        if (title === '') {
          setTitle(chunk.model);
        }
        if (chunk.choices[0].finish_reason === 'stop') {
          setFinish(true);
        }
        if (chunk.choices[0].delta.content) {
          setResponse((response: any) => response + chunk.choices[0].delta.content);
          if (content.current) {
            content.current.scrollTop = content.current.scrollHeight;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onKeyUpHandler = (e: KeyboardEvent<HTMLDivElement>) => {
    console.log('🚀 ~ onKeyUpHandler ~ e:', e);
    if (e.key === 'Enter' && !e.shiftKey) {
      try {
        if (!isLoading) {
          onSubmit({
            searchKeyword: getValues('searchKeyword'),
          });
          //   methods.handleSubmit(onSubmit);
        }
      } catch (e) {
        console.log(e);
      }

      e.preventDefault();
    }
  };

  const onClose = () => {
    if (completion && completion.controller) {
      completion.controller.abort();
    }
    setLoading(false);
    setFinish(false);
    setResponse('');
  };

  const searchForm = (isPopup = false) => {
    return (
      <StyledFormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(onSubmit)}
        formId={'OpenAiForms'}
        sx={isPopup ? { flex: 1, pl: 0 } : {}}
      >
        <RowContainer
          sx={{
            width: '100%',
            flex: 1,
            borderBottom: '1px solid ',
            borderRadius: 1,
            borderColor: theme.palette.primary.light,
          }}
        >
          <RHFTextField
            name="searchKeyword"
            label="GPT 검색"
            size={'small'}
            value={undefined}
            onKeyUp={onKeyUpHandler}
            onKeyDown={undefined}
            disabled={isLoading}
            maxRows={4}
            multiline={isPopup}
            InputProps={{
              style: {
                paddingRight: 0,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', width: 34, height: 40 }}>
                      <Iconify icon={'line-md:loading-twotone-loop'} width={20} />
                    </Box>
                  ) : (
                    <SearchButton
                      type={'submit'}
                      variant="outlined"
                      form={'OpenAiForms'}
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        // methods.handleSubmit(onSubmit);
                        onSubmit({
                          searchKeyword: getValues('searchKeyword'),
                        });
                      }}
                    >
                      <Iconify icon={'icon-park-outline:search'} width={20} />
                    </SearchButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </RowContainer>
      </StyledFormProvider>
    );
  };

  return (
    <>
      {searchForm()}

      {response !== '' && (
        <Dialog open={response !== ''} fullWidth maxWidth="md" scroll="paper">
          <DialogTitleWithCloseButton
            title={''}
            subtitle={title.toUpperCase() + ' Says...'}
            onClose={onClose}
          />
          <DialogContent
            sx={{
              '> *': {
                fontSize: '14px',
              },
            }}
            ref={content}
          >
            {isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255, 255, 255, 0.7)',
                }}
              >
                <Iconify icon={'line-md:loading-twotone-loop'} width={20} />
              </Box>
            )}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                filter: isLoading ? 'blur(1px)' : 'none',
              }}
            >
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return match ? (
                      <SyntaxHighlighter
                        language={match[1]}
                        PreTag="div"
                        style={materialDark}
                        //   {...props}
                      >
                        {String(children).replace(/\n$/, '')}
                      </SyntaxHighlighter>
                    ) : (
                      <code {...props}>{children}</code>
                    );
                  },
                  img: (image) => <Image src={image.src || ''} />,
                }}
              >
                {response}
              </ReactMarkdown>
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ flex: 1, alignItems: 'baseline' }}
            >
              {searchForm(true)}
              {/* <Stack direction={'row'} spacing={1}>
                <Button onClick={onClose} variant="contained" color="error">
                  Close
                </Button>
                <Button type="submit" variant="contained" form={'OpenAiForms'} disabled={!isFinish}>
                  Again!!
                </Button>
              </Stack> */}
            </Stack>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
});

export default OpenAiForm;

const StyledFormProvider = styled(FormProvider)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
}));

const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  '& > *': {
    flex: 1,
  },
}));

const SearchButton = styled(Button)({
  p: 0,
  minWidth: 30,
  height: 40,
  border: 'none !important',
});
