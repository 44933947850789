import { useState } from 'react';
import { ChatbotHistoryResponse, ChatList, ChatListRequest } from 'src/@types/apiResponseTypes';
import { chatbotApi } from 'src/api';

import { ConversationType } from '../utils/types';

function useChatResponse() {
  const [chatList, setChatList] = useState<ChatList[]>([]);
  const [chatbotHistoryResponse, setChatbotHistoryResponse] = useState<
    ChatbotHistoryResponse | undefined
  >(undefined);
  const [conversations, setConversations] = useState<ConversationType[] | undefined>(undefined);

  const getChatList = async (chatListRequest: ChatListRequest) => {
    try {
      const data = await chatbotApi.getChatList(chatListRequest);
      if (data) {
        const sortByLatestTime = data.chat_list.sort(
          (a, b) => new Date(b.reg_dt).getTime() - new Date(a.reg_dt).getTime(),
        );
        setChatList(sortByLatestTime);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const findChatHistory = async ({ chat_id }: { chat_id: string }) => {
    try {
      const data = await chatbotApi.findChatHistory({ chat_id });
      if (data) {
        const newConversation: ConversationType[] = data.history.map(
          ({ ord, user_id, user_input, response, reg_dt }) => ({
            aiResponse: { chat_id, user_id, response, ord, reg_dt },
            userMessage: { user_id, user_input },
          }),
        );
        setConversations(newConversation);
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteChat = async ({ chat_id }: { chat_id: string }) => {
    try {
      const data = await chatbotApi.deleteChat({ chat_id });
      if (data) {
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAllChats = async (user_id: number) => {
    try {
      const data = await chatbotApi.deleteAllChat(user_id);
      if (data) {
        setChatbotHistoryResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    list: { chatList, getChatList },
    history: { chatbotHistoryResponse, setChatbotHistoryResponse, findChatHistory },
    conv: { conversations, setConversations },
    onDeleteChatRoom: {
      deleteChat,
      deleteAllChats,
    },
  };
}

export default useChatResponse;
