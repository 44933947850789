import { Button, Stack, styled } from '@mui/material';
import { GridSlotsComponentsProps, useGridApiContext } from '@mui/x-data-grid-pro';
import { isEmpty } from 'lodash';
import SearchFilter from 'src/components/SearchFilter';

// ------------------------------------------------------------------------------
const StyledCGridHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 0),
}));

/**
 *
 * # CGridHeader 설명
 * - DataGridPro 컴포넌트의 커스텀 헤더 컴포넌트
 * - 검색 필드, 선택 항목 삭제 버튼, 등록 버튼을 포함한다.
 */
export default function CGridHeader({
  onClickDelete,
  deleteButtonTitle = '삭제',
  onClickAdd,
  addButtonTitle = '등록',
  onSearch,
  textFieldProps,
  leftFieldChildren,
}: NonNullable<GridSlotsComponentsProps['toolbar']>) {
  const apiRef = useGridApiContext();
  const selectedRows = apiRef.current?.getSelectedRows();

  // DataGridProApiRef가 없을 경우, 빈 컴포넌트 반환
  if (!apiRef.current) return <></>;

  // 선택 항목 삭제 버튼 클릭 시
  const handleDelete = () => {
    if (!onClickDelete) return;
    const rows = Array.from(selectedRows, ([_, value]) => value);
    onClickDelete(rows);
  };

  // 등록 버튼 클릭 시
  const handleAdd = () => {
    if (!onClickAdd) return;
    onClickAdd();
  };

  return (
    <StyledCGridHeaderContainer>
      <Stack direction="row" gap={2} alignItems="center">
        {/* 좌측 필드 */}
        {leftFieldChildren}
        {/* 검색 필드 */}
        {onSearch && <SearchFilter onSearch={onSearch} inputProps={textFieldProps} />}
      </Stack>
      {/* 우측 필드 */}
      <Stack direction="row" gap={2} alignItems="center">
        {/* 선택 항목 삭제 버튼 */}
        {onClickDelete && (
          <Button variant="outlined" onClick={handleDelete} disabled={isEmpty(selectedRows)}>
            {deleteButtonTitle}
          </Button>
        )}
        {/* 등록 버튼 */}
        {onClickAdd && (
          <Button variant="contained" onClick={handleAdd}>
            {addButtonTitle}
          </Button>
        )}
      </Stack>
    </StyledCGridHeaderContainer>
  );
}
