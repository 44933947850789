import {
  AddChatbotFAQPayload,
  ChatbotFAQ,
  ChatbotHistoryResponse,
  ChatList,
  ChatListRequest,
} from 'src/@types/apiResponseTypes';

import { chatbotStreamInstance } from '../axios';

const SUB_URL = '/v1/chatbot';

export const getChatList = async ({ user_id, page = 1, size = 10 }: ChatListRequest) => {
  const url = `${SUB_URL}/chats?user_id=${user_id}&page=${page}&size=${size}`;

  try {
    const response = await chatbotStreamInstance.get<{ chat_list: ChatList[] }>(url);
    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const findChatHistory = async ({ chat_id }: { chat_id: string }) => {
  const url = `${SUB_URL}/chats/${chat_id}`;

  try {
    const response = await chatbotStreamInstance.get<ChatbotHistoryResponse>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteChat = async ({ chat_id }: { chat_id: string }) => {
  const url = `${SUB_URL}/chats/${chat_id}`;

  try {
    const response = await chatbotStreamInstance.delete<ChatbotHistoryResponse>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteAllChat = async (user_id: number) => {
  const url = `${SUB_URL}/chats`;

  try {
    const response = await chatbotStreamInstance.delete<ChatbotHistoryResponse>(url, {
      params: { user_id },
    });

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

// -------------------------------------------------------------
// 챗봇 FAQ API
export const getFAQs = async () => {
  try {
    const url = `${SUB_URL}/faqs`;
    const response = await chatbotStreamInstance.get<{ faqs: ChatbotFAQ[] }>(url);

    if (response.status === 200 && response.data) {
      return response.data.faqs;
    }
  } catch (error) {
    console.error(error);
  }
};

export const findFAQ = async (faq_id: number) => {
  try {
    const url = `${SUB_URL}/faqs/${faq_id}`;
    const response = await chatbotStreamInstance.get<ChatbotFAQ>(url);

    if (response.status === 200 && response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addFAQ = async (payload: AddChatbotFAQPayload) => {
  try {
    const url = `${SUB_URL}/faqs`;
    const response = await chatbotStreamInstance.post(url, payload);

    if (response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateFAQ = async (chatFormData: ChatbotFAQ) => {
  try {
    const url = `${SUB_URL}/faqs`;
    const response = await chatbotStreamInstance.put(url, chatFormData);

    if (response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteFAQ = async (faq_ids: number[]) => {
  try {
    const url = `${SUB_URL}/faqs`;
    const response = await chatbotStreamInstance.delete(url, { data: { faq_ids } });
    if (response.status === 200 && response.data) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
