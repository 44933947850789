// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';
import {
  DateTimeRangePicker,
  DateTimeRangePickerProps,
} from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import { format, isDate } from 'date-fns';

interface RHFDatePickerProps extends DatePickerProps<Date> {
  name: string;
  size?: 'medium' | 'small' | undefined;
}

export default function RHFDatePicker({ name, size = 'medium', ...other }: RHFDatePickerProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const date = new Date(field.value);
        const dateValue = isDate(date) ? date : new Date();
        return (
          <DatePicker
            {...field}
            value={dateValue}
            onChange={(newValue) => {
              if (newValue) {
                field.onChange(format(newValue, 'yyyy-MM-dd'));
              }
            }}
            slotProps={{
              textField: {
                error: Boolean(error),
                helperText: error ? error.message : undefined,
                size: size,
              },
            }}
            {...other}
          />
        );
      }}
    />
  );
}

interface RHFDateTimeRangePickerProps extends DateTimeRangePickerProps<Date> {
  name: string;
}

export function RHFDateTimeRangePicker({ name, ...other }: RHFDateTimeRangePickerProps) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const startDate = field.value && field.value[0] ? new Date(field.value[0]) : new Date();
        const endDate = field.value && field.value[1] ? new Date(field.value[1]) : new Date();
        const startValue = isDate(startDate) ? startDate : new Date();
        const endValue = isDate(endDate) ? endDate : new Date();
        return (
          <DateTimeRangePicker
            {...field}
            value={[startValue, endValue]}
            onChange={(newValue) => {
              if (typeof newValue === 'object') {
                if (newValue[0] && newValue[1]) {
                  field.onChange([
                    format(newValue[0], "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
                    format(newValue[1], "yyyy-MM-dd'T'HH:mm:ss.SSSX"),
                  ]);
                }
              }
            }}
            views={['day', 'hours']}
            timeSteps={{ minutes: 20 }}
            viewRenderers={{ hours: renderDigitalClockTimeView }}
            {...other}
          />
        );
      }}
    />
  );
}
