import { Box, Typography } from '@mui/material';

import { isMobile } from '../../utils/common';

import SuggestedQuestions from './SuggestedQuestions';

function InitialChatList() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px 36px',
          borderRadius: '10px',
          backgroundColor: '#E9F9E8',
          margin: isMobile() ? '16px' : 0,
        }}
      >
        <Box>
          <Typography variant="h6">어려움이나 궁금한 점을 해결해 드릴게요</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            AI 챗봇이 질문에 대하여 올바르지 않은 답변을 할 수 있어요. 챗봇 성능 향상을 위해 대화
            내용이 1년간 보관되어요. 개인 정보나 민감한 정보가 포함된 질문을 하지 말아 주세요.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          rowGap: '10px',
          padding: '8px 16px',
        }}
      >
        <SuggestedQuestions />
      </Box>
    </>
  );
}

export default InitialChatList;
