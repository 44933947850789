import { useCallback, useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { NavListProps, NavSectionProps } from 'src/components/nav-section';
import { useStores } from 'src/models';
import { IMenuSnapshot } from 'src/models/user/User';
import { PATH_PAGE } from 'src/routes/paths';
import { MENU_TYPE_CODE_TO_ENG, REPORT_TYPES } from 'src/static/constants';

function useNavData() {
  const { commonCodeStore, authStore } = useStores();
  const MENU_TYPES = commonCodeStore.getCCodeByName('MENU_TYPE_CD');
  const [menus, setMenus] = useState<Array<IMenuSnapshot>>([]);
  const [navData, setNavData] = useState<NavSectionProps['data']>([]);

  useEffect(() => {
    const disposer = autorun(() => {
      if (authStore.user) {
        setMenus(authStore.user.menuList);
      }
    });

    return () => disposer();
  }, [authStore.user]);

  const getNavData = useCallback(async () => {
    if (menus.length === 0) return;

    // 메뉴 아이템에 하위 active 메뉴를 추가하는 함수
    const getCustomActivePaths = (menu: IMenuSnapshot): string[] => {
      switch (menu.menuUri) {
        case PATH_PAGE.management.report.root:
          return [
            `${PATH_PAGE.management.report.create}?reportType=${REPORT_TYPES.WORK}`,
            `${PATH_PAGE.management.report.edit}?reportType=${REPORT_TYPES.WORK}`,
          ];
        case PATH_PAGE.management.report.team:
          return [
            `${PATH_PAGE.management.report.create}?reportType=${REPORT_TYPES.TEAM}`,
            `${PATH_PAGE.management.report.edit}?reportType=${REPORT_TYPES.TEAM}`,
          ];
        case PATH_PAGE.management.report.headquarter:
          return [
            `${PATH_PAGE.management.report.create}?reportType=${REPORT_TYPES.HEADQUARTER}`,
            `${PATH_PAGE.management.report.edit}?reportType=${REPORT_TYPES.HEADQUARTER}`,
          ];
        case PATH_PAGE.management.report.meeting:
          return [
            `${PATH_PAGE.management.report.create}?reportType=${REPORT_TYPES.MEETING}`,
            `${PATH_PAGE.management.report.edit}?reportType=${REPORT_TYPES.MEETING}`,
          ];
        default:
          return [];
      }
    };

    // 메뉴 아이템에 하위 메뉴를 추가하는 함수
    const appendChildrenMenu = (menu: IMenuSnapshot): NavListProps[] => {
      const children = menus.filter((m) => m.menuPsid === menu.menuSid);
      return children
        .sort((a, b) => a.ordr - b.ordr)
        .map((child) => ({
          title: child.menuNm,
          path: child.menuUri,
          children: appendChildrenMenu(child),
          activePaths: getCustomActivePaths(child),
        }));
    };

    // get subheader
    const getSubheader = (menuType: { code: number }): string => {
      const subheader = (MENU_TYPE_CODE_TO_ENG as typeof MENU_TYPE_CODE_TO_ENG)[
        menuType.code as keyof typeof MENU_TYPE_CODE_TO_ENG
      ];

      return subheader ? subheader.toUpperCase() : '';
    };

    // 메뉴 타입별로 메뉴를 그룹화
    const data: NavSectionProps['data'] = MENU_TYPES.map((menuType) => ({
      subheader: getSubheader(menuType),
      items: menus
        .filter((menu) => menu.menuTypeCd.code === menuType.code)
        .sort((a, b) => a.ordr - b.ordr)
        .map((menu) => ({
          title: menu.menuNm,
          path: menu.menuUri,
          children: appendChildrenMenu(menu).length > 0 ? appendChildrenMenu(menu) : undefined,
          activePaths: getCustomActivePaths(menu),
        })),
    }));

    // 메뉴 아이템이 하나도 없는 섹션은 제외
    const navData = data.filter((section) => section.items.length > 0);

    setNavData(navData);
  }, [MENU_TYPES, menus]);

  useEffect(() => {
    getNavData();
  }, [getNavData]);

  return navData;
}

export default useNavData;
