import { useState } from 'react';
import { useForm } from 'react-hook-form';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Typography } from '@mui/material';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';

import { MessageInfo } from '../../utils/types';

type InputTypes = Pick<MessageInfo, 'response'>;

interface MessageInputAreaProps {
  isAiProcessing: boolean;
  onSendMessageToAI: (response: string) => void;
}

function MessageInputArea({ isAiProcessing, onSendMessageToAI }: MessageInputAreaProps) {
  const MAX_CHARS = 300;
  // 채팅창에 한국어 문장 입력 후 엔터 누를 시, 채팅창에 한국어 문장에 이어서 한국어 문장의 마지막 글자까지 입력되는 버그 방지
  const [isComposing, setIsComposing] = useState<boolean>(false);

  const methods = useForm<InputTypes>({
    defaultValues: {
      response: '',
    },
  });
  const { reset, watch } = methods;

  const inputMessage = watch('response');

  const onHandleSubmit = async ({ response }: InputTypes) => {
    // 사용자가 채팅창에 실제 문자 없이 공백 문자만 입력할 경우, 메시지 전송 방지
    if (response.trim()) {
      /**
       * 사이드 채팅 메뉴바에 선택되지 않았을 때(앱 초기 렌더링 직후, 채팅 리스트 중 하나를 삭제하고 난 경우)
       * 새로운 채팅 생성
       */
      // 메시지를 AI가 있는 백엔드에 보내기
      onSendMessageToAI(response);
      // 채팅 인풋창 초기화
      reset({ response: '' });
    }
  };

  // 키보드 엔터 누를 때 메시지 전송하는 함수
  const handleEnterKeyPress = (event: React.KeyboardEvent, handleSubmit: any) => {
    // AI가 메시지를 생성 중일 때 엔터키 방지.
    if (!isAiProcessing) {
      /**
       * event.key === 'Enter' && !event.shiftKey 의미
       * 동시에 엔터키를 눌렀고 쉬프트 키를 안 눌렀으면 메시지 전송,
       * 동시에 엔터키를 눌렀고 쉬프트 키를 눌렀으면 줄바꿈 (TextField의 multiline 옵션).
       */
      const shouldSendMessage = event.key === 'Enter' && !event.shiftKey;
      if (!isComposing && shouldSendMessage) {
        event.preventDefault();
        handleSubmit(onHandleSubmit)();
      }
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 12px 16px',
        gap: 8,
      }}
    >
      <FormProvider
        methods={methods}
        onSubmit={methods.handleSubmit(onHandleSubmit)}
        sx={{
          border: '2px solid #0F85FB',
          borderRadius: '8px',
          padding: '20px',
        }}
      >
        <RHFTextField
          multiline // Shift + Enter 키 누르면 줄바꿈을 하게 해줌
          name="response"
          placeholder="궁금한 사항을 구체적으로 작성해주세요."
          variant="standard"
          inputProps={{ maxLength: MAX_CHARS }}
          inputRef={(textFieldInput) => textFieldInput && textFieldInput.focus()} // <TextField /> 가 렌더링 되었을 때 자동으로 텍스트 필드에 포커싱하기
          sx={{
            '& .MuiInputBase-root': {
              border: 'none',
              borderRadius: '8px',
              height: '180px', // 높이를 180px로 설정
              overflow: 'auto',
            },
            '& .MuiInputBase-input': {
              height: '100% !important', // 입력 영역이 전체 높이를 차지하도록 설정
              overflowY: 'auto !important', // 세로 스크롤 활성화
            },
            // --------------------------------------------------------------------------
            /** TextField의 기본적으로 있는 검은색 밑줄 제거 */
            '& .MuiInput-underline:before': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:after': {
              borderBottom: 'none',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            // --------------------------------------------------------------------------
          }}
          onKeyDown={(event) => handleEnterKeyPress(event, methods.handleSubmit)}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={() => setIsComposing(false)}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
            {inputMessage.length} / {MAX_CHARS}
          </Typography>
          <Button
            variant="outlined"
            sx={{ borderRadius: '100px' }}
            startIcon={<SendIcon sx={{ fontSize: 13 }} />}
            disabled={inputMessage.length === 0 || inputMessage.length > MAX_CHARS}
            onClick={() => {
              onHandleSubmit({ response: methods.getValues().response });
            }}
          >
            보내기
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}

export default MessageInputArea;
