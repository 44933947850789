import React from 'react';
import { Box } from '@mui/material';
import innerMateLog from 'src/assets/images/inner_mate_logo.png';

import { SIDEBAR_BACKGROUND_COLOR } from '../../utils/contants';

function SidebarForDesktop({
  children,
  newButtonComp,
}: {
  children: React.ReactNode;
  newButtonComp: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: SIDEBAR_BACKGROUND_COLOR,
        alignItems: 'flex-start',
        position: 'relative',
        borderLeft: '1px solid rgb(216, 226, 253)',
        borderRight: '1px solid rgb(216, 226, 253)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          background: '#1565C0',
          paddingY: '15px',
          paddingLeft: '15px',
          width: '100%',
          borderBottom: 'solid 1px #FFF',
        }}
      >
        <img src={innerMateLog} alt="innermate_logo" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          verticalAlign: 'middle',
          width: '100%',
          padding: '15px 10px 0',
        }}
      >
        {newButtonComp}
      </Box>
      {children}
    </Box>
  );
}

export default SidebarForDesktop;
