import { useState } from 'react';
import { Chip } from '@mui/material';

function SuggestedQuestions() {
  const [suggestions, setSuggestions] = useState<{ label: string }[]>([]);
  const NUMBER_OF_SUGGESTIONS = 7;

  return (
    <>
      {suggestions.length > 0 &&
        suggestions.map(({ label }) => (
          <Chip
            key={label}
            sx={{
              padding: '16px 4px',
              border: '1px solid #3280CEA6',
              maxWidth: '320px',
              fontWeight: label.includes('더보기') ? 'bold' : 'normal',
            }}
            label={label}
            variant="outlined"
            onClick={() => {
              console.log('hello');
            }}
          />
        ))}
      {suggestions.length > 0 && suggestions.length < NUMBER_OF_SUGGESTIONS && (
        <Chip
          sx={{
            padding: '16px 4px',
            border: '1px solid #3280CEA6',
            maxWidth: '320px',
            fontWeight: 'bold',
          }}
          label="+ 더보기"
          variant="outlined"
          onClick={() => {
            // +더보기 버튼 누르면 suggestions 추가하는 버튼 이벤트
            setSuggestions((prevSuggestions) => [...prevSuggestions]);
          }}
        />
      )}
    </>
  );
}

export default SuggestedQuestions;
