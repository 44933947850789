import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Chip,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import remarkGfm from 'remark-gfm';
import { AIResponse, ChatbotFAQ } from 'src/@types/apiResponseTypes';
import { EditorView } from 'src/components/block-editor';
import Image from 'src/components/image';

import { isMobile } from '../../utils/common';
import { SmileMessageIcon } from '../../utils/Icons';
import { ConversationType, isChatbotFAQType, isConversationTypeArray } from '../../utils/types';
import MessageInputArea from '../message-input-area';
import NewChatButton from '../NewChatButton';

import InitialChatList from './InitialChatList';

interface ChatWindowProps {
  isStreaming: boolean;
  aiResponse: AIResponse;
  aiResponseStatus: { isAITyping: boolean; error: string | null };
  chatRoom: Array<ConversationType> | ChatbotFAQ;
  currentChatRoomId: string;
  onAddNewChatRoom: () => void;
  onSendMessageToAI: (response: string) => Promise<void>;
  onClose: () => void;
}

function ChatWindow({
  isStreaming,
  aiResponse,
  aiResponseStatus,
  chatRoom,
  currentChatRoomId,
  onAddNewChatRoom,
  onSendMessageToAI,
  onClose,
}: ChatWindowProps) {
  const { response: aiCurrentResponse } = aiResponse;
  const { isAITyping, error } = aiResponseStatus;

  const isAiProcessing = isAITyping || isStreaming;

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const isConversationType = isConversationTypeArray(chatRoom);
  const isInnerwaveGuide = isChatbotFAQType(chatRoom);

  useEffect(() => {
    scrollToBottom();
  }, [chatRoom, currentChatRoomId, isAITyping, isStreaming]);

  if (isConversationType) {
    return (
      <Layout
        bottomComponent={
          <MessageInputArea isAiProcessing={isAiProcessing} onSendMessageToAI={onSendMessageToAI} />
        }
        onClose={onClose}
      >
        {/* 채팅방이 새로 추가 되었거나 삭제되었을 때 초기 화면을 보여주기 */}
        {chatRoom.length === 0 ? (
          <InitialChatList />
        ) : (
          <>
            <List>
              {error && <ListItem>{error}</ListItem>}
              {chatRoom.map((conv, index) => (
                <div key={index}>
                  {conv.userMessage.user_input && (
                    <StyledListItem sx={{ display: 'flex', columnGap: '7px', fontWeight: 'bold' }}>
                      <SmileMessageIcon /> {conv.userMessage.user_input}
                    </StyledListItem>
                  )}
                  {conv.aiResponse.response && (
                    <StyledListItem
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        borderBottom: 0,
                      }}
                    >
                      <CustomReactMarkdown text={conv.aiResponse.response} />
                    </StyledListItem>
                  )}
                </div>
              ))}
              {!error && isStreaming && (
                <StyledListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    borderBottom: 0,
                  }}
                >
                  <CustomReactMarkdown text={aiCurrentResponse} />
                </StyledListItem>
              )}
              {isAITyping && (
                <StyledListItem>잠시만요 알맞은 답변을 생각하고 있어요...</StyledListItem>
              )}
              {/* 스크롤을 밑으로 가게 해주는 엘리먼트 */}
            </List>
            <div ref={messagesEndRef} />
          </>
        )}
      </Layout>
    );
  }

  if (isInnerwaveGuide) {
    const { tag, answer } = chatRoom;

    return (
      <Layout
        bottomComponent={
          <Box sx={{ padding: '16px 12px' }}>
            <NewChatButton disabled={isAiProcessing} onClick={onAddNewChatRoom} />
          </Box>
        }
        onClose={onClose}
      >
        <ListItem sx={{ padding: '8px 16px' }}>
          {/* key값을 주어 강제로 리렌더링을 시켜서 업데이트된 정보를 화면에 보이게 함. */}
          <EditorView key={answer} content={answer} />
        </ListItem>
        <ListItem sx={{ padding: '8px 16px', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
          {tag
            ? tag.split(',').map((t) => (
                <Chip
                  key={t}
                  sx={{ margin: '3px' }}
                  size="medium"
                  label={`# ${t}`}
                  onClick={() => {
                    console.log('hihi');
                  }}
                />
              ))
            : null}
        </ListItem>
      </Layout>
    );
  }

  return (
    <Layout
      bottomComponent={
        <MessageInputArea isAiProcessing={isAiProcessing} onSendMessageToAI={onSendMessageToAI} />
      }
    >
      <InitialChatList />
    </Layout>
  );
}

const Layout = ({
  children,
  bottomComponent,
  onClose,
}: {
  children: React.ReactNode;
  bottomComponent?: React.ReactNode;
  onClose?: () => void;
}) => (
  <Stack
    direction="column"
    sx={{
      display: 'flex',
      width: isMobile() ? '100vw' : '500px',
      height: '100%',
      flexDirection: 'column',
      background: 'white',
      overflow: 'auto',
    }}
  >
    <Stack direction="row" sx={{ display: 'flex', backgroundColor: '#1565C01A' }}>
      <Typography
        variant="h5"
        sx={{
          flex: 1,
          display: isMobile() ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'end',
          paddingY: '15px',
        }}
      >
        회사의 업무를 조금 더 쉽게!
      </Typography>
      <IconButton onClick={onClose}>
        <CloseRoundedIcon />
      </IconButton>
    </Stack>
    <Box
      sx={{
        minHeight: '279px',
        overflow: 'auto',
        flex: '1',
        paddingTop: isMobile() ? '15px' : 0,
      }}
    >
      {children}
    </Box>
    {bottomComponent}
  </Stack>
);

const CustomReactMarkdown = ({ text }: { text: string }) => (
  <ReactMarkdown
    remarkPlugins={[remarkGfm]}
    components={{
      code({ className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return match ? (
          <SyntaxHighlighter language={match[1]} PreTag="div" style={materialDark}>
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        ) : (
          <code {...props}>{children}</code>
        );
      },
      img: (image) => <Image src={image.src || ''} />,
      a(props) {
        const { href, children } = props;
        return (
          <Link
            href={href}
            target="_blank"
            sx={{
              color: '#3280CE',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {children || href}
          </Link>
        );
      },
    }}
  >
    {text}
  </ReactMarkdown>
);

const StyledListItem = styled(ListItem)(() => ({
  padding: '8px 16px',
  borderBottom: '1px solid #B9B9B9',
}));

export default ChatWindow;
