import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  alpha,
  Box,
  Button,
  Divider,
  Drawer,
  InputAdornment,
  List,
  ListItem,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { IconButtonAnimate } from 'src/components/animate';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify/Iconify';

interface Props {
  fixed?: string | boolean;
  setFixed: Dispatch<SetStateAction<boolean>>;
}

export default function UtilsDial({ fixed = false, setFixed }: Props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [openSetting, setOpenSetting] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleClick = () => {
    if (fixed) {
      localStorage.removeItem('utilDialFixed');
      setFixed(false);
    } else {
      localStorage.setItem('utilDialFixed', 'true');
      setFixed(true);
    }
    setFixed && setFixed(!fixed);
  };

  const getDefaultValue = () => {
    const storage = JSON.parse(localStorage.getItem('works-setting-util') || '{}');
    const obj: { [key: string]: any } = {};
    actions.forEach((action) => {
      if (action.divider) return;
      const chk = 'chk-' + action.name?.replace(/ /gi, '');
      const link = 'link-' + action.name?.replace(/ /gi, '');
      obj[chk] = storage[chk] !== undefined ? storage[chk] : true;
      obj[link] = storage[link]
        ? storage[link]
        : actions.find((r) => r.name?.replace(/ /gi, '') === action.name?.replace(/ /gi, ''))?.link;
    });
    return obj;
  };

  const [defaultValue, setDefaultValue] = useState<any>(getDefaultValue());
  const methods = useForm<any>({
    defaultValues: defaultValue,
  });
  const { setValue } = methods;

  const onSubmit = async (form: any) => {
    localStorage.setItem('works-setting-util', JSON.stringify(form));
    settingHandler();
  };

  const settingHandler = () => {
    const obj = getDefaultValue();
    Object.keys(obj).forEach((o) => {
      setValue(o, obj[o]);
    });
    setDefaultValue(obj);
    setOpenSetting(!openSetting);
  };

  const handleIconClick = (_name: string | undefined) => {
    const name = _name?.replace(/ /gi, '');
    const link = defaultValue['link-' + name];
    if (name && link && link.length > 1) {
      window.open(link, '_blank');
    }
  };

  if (fixed) {
    return (
      <>
        <Stack
          id="tools"
          direction={'column'}
          justifyContent={'space-between'}
          spacing={1}
          sx={{
            position: 'relative',
            top: -34,
            height: 'calc(100% + 24px)',
            // px: 1,
            pt: 3,
            zIndex: 1299,
            boxShadow: '0px 10px 10px rgba(0,0,0,0.07)',
          }}
        >
          <Stack spacing={1}>
            <Tooltip key={'fixe-icon'} arrow={true} title={'Hide'} placement={'left'}>
              <IconButtonAnimate sx={{ width: 40, height: 40 }} onClick={handleClick}>
                <Iconify icon={'f7:pin-slash'} />
              </IconButtonAnimate>
            </Tooltip>
            {actions.map((action, i) =>
              action.divider ? (
                <Divider key={'divider-' + i} />
              ) : (
                defaultValue['chk-' + action.name?.replace(/ /gi, '')] === true && (
                  <Tooltip key={action.name} arrow={true} title={action.name} placement={'left'}>
                    <IconButtonAnimate
                      sx={{ width: 40, height: 40 }}
                      onClick={() => {
                        handleIconClick(action.name);
                      }}
                    >
                      {action.icon}
                    </IconButtonAnimate>
                  </Tooltip>
                )
              ),
            )}
          </Stack>
          <Box sx={{ pb: 1 }} key={'util-setting'}>
            <Divider />
            <Tooltip key={'Setting'} arrow={true} title={'Setting'} placement={'left'}>
              <IconButtonAnimate sx={{ width: 40, height: 40 }} onClick={settingHandler}>
                <Icon icon={'uil:setting'} />
              </IconButtonAnimate>
            </Tooltip>
          </Box>
        </Stack>
        {openSetting && (
          <Drawer
            anchor={'right'}
            elevation={2}
            open={openSetting}
            onClose={settingHandler}
            PaperProps={{
              sx: {
                // ...bgBlur({ color: theme.palette.background.default, opacity: 0.9 }),
                width: 400,
                boxShadow: `-24px 12px 40px 0 ${alpha(
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[500]
                    : theme.palette.common.black,
                  0.16,
                )}`,
                p: 3,
              },
            }}
            sx={{
              zIndex: 1300,
            }}
          >
            <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h5'}>Setting</Typography>
                <Button size={'small'} variant={'contained'} type={'submit'} sx={{ minWidth: 40 }}>
                  Save
                </Button>
              </Stack>
              <Stack
                justifyContent={'space-between'}
                sx={{ height: '100%', flex: 1, position: 'relative', mt: 2 }}
              >
                <Stack spacing={1}>
                  <List>
                    {actions.map((action, i) =>
                      action.divider ? (
                        <Divider key={'divider-' + i} />
                      ) : (
                        <ListItem key={action.name} sx={{ px: 0 }}>
                          <Stack sx={{ width: '100%' }} direction={'column'}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                {action.icon}
                                <Typography variant="body2">{action.name}</Typography>
                              </Stack>
                              <RHFSwitch
                                id={'chk-' + action.name?.replace(/ /gi, '') || ''}
                                name={'chk-' + action.name?.replace(/ /gi, '') || ''}
                                label={''}
                                sx={{ mr: 0, width: 50 }}
                              />
                            </Stack>
                            <RHFTextField
                              name={'link-' + action.name?.replace(/ /gi, '') || ''}
                              size={'small'}
                              sx={{ flex: 1 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Typography variant={'caption'}>URL</Typography>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Stack>
                        </ListItem>
                      ),
                    )}
                  </List>
                </Stack>
              </Stack>
              <Box sx={{ position: 'fixed', bottom: 10, right: 10 }}>
                <IconButtonAnimate
                  onClick={settingHandler}
                  sx={{ background: '#FFF', borderRadius: '50%', boxShadow: theme.shadows[10] }}
                >
                  <Icon icon={'carbon:close'} />
                </IconButtonAnimate>
              </Box>
            </FormProvider>
          </Drawer>
        )}
      </>
    );
  }

  return (
    <>
      <SpeedDial
        ariaLabel="utils-dial"
        sx={{ position: 'absolute', top: 6, right: 16, zIndex: 1299 }}
        icon={
          <SpeedDialIcon
            icon={
              <Iconify
                icon="mdi:favorite-circle-outline"
                width={22}
                height={22}
                color={theme.palette.primary.main}
              />
            }
            openIcon={
              <Iconify
                icon="mi:pin"
                width={22}
                height={22}
                sx={{
                  mt: '2px',
                }}
              />
            }
            sx={{}}
          />
        }
        direction="left"
        onClose={handleClose}
        onOpen={handleOpen}
        onClick={handleClick}
        open={open}
        FabProps={{
          sx: {
            display: 'flex',
            alignItems: 'center',
            width: 32,
            height: 32,
            bgcolor: 'transparent',
            boxShadow: theme.shadows[8],
            '& .MuiSpeedDialIcon-root': {
              color: 'primary.main',
            },
            '&:hover': {
              bgcolor: 'primary.main',
              '& .MuiSpeedDialIcon-root': {
                color: '#FFFFFF',
              },
            },
          },
        }}
      >
        {actions.map(
          (action) =>
            action.horizontal && (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => {
                  if (action.link) {
                    window.open(action.link, '_blank');
                  }
                }}
              />
            ),
        )}
      </SpeedDial>
    </>
  );
}

function openChatbotWindowCustomEvent() {
  const event = new CustomEvent('chatWindowCloseEvent', {
    detail: {
      open: true,
    },
  });
  window.dispatchEvent(event);
}

const Icon = styled(Iconify)(({ theme }) => ({
  width: 24,
  height: 24,
}));

const actions = [
  {
    icon: (
      <Icon
        icon={'tabler:message-chatbot'}
        color={'#3280CE'}
        onClick={(e) => {
          e.preventDefault();
          openChatbotWindowCustomEvent();
        }}
      />
    ),
    name: 'InnerMate',
    horizontal: true,
  },
  {
    icon: <Icon icon={'ion:mail-outline'} color={'#7E349D'} />,
    name: 'HiWorks - Mail',
    link: 'https://mail.office.hiworks.com/innerwave.co.kr',
    horizontal: true,
  },
  {
    icon: <Icon icon={'ph:calendar-light'} color={'#7E349D'} />,
    name: 'HiWorks - Schedule',
    link: 'https://calendar.office.hiworks.com/innerwave.co.kr/schedule/schedulemain',
    horizontal: true,
  },
  {
    icon: <Icon icon={'carbon:time'} color={'#7E349D'} />,
    name: 'HiWorks - Booking',
    link: 'https://booking.office.hiworks.com/innerwave.co.kr/booking/bookingResource/resourceList/682',
    horizontal: true,
  },
  {
    icon: <Icon icon={'solar:document-add-broken'} color={'#7E349D'} />,
    name: 'HiWorks - Doc',
    link: 'https://approval.office.hiworks.com/innerwave.co.kr/approval/document/lists/A',
    horizontal: true,
  },
  {
    divider: true,
    horizontal: false,
  },
  {
    icon: <Icon icon={'uil:hdd'} color={'#7E349D'} />,
    name: 'NAS',
    link: 'https://192.168.40.105:9443',
    horizontal: true,
  },
  {
    icon: <Icon icon={'tabler:file-type-doc'} color={'#0077C0'} />,
    name: 'Innerwave Docs',
    link: 'https://docs.surfinn.co.kr/',
    horizontal: false,
  },
  {
    icon: <Icon icon={'simple-icons:redmine'} color={'#0077C0'} />,
    name: 'Redmine',
    link: 'https://redmine.surfinn.kr/projects',
    horizontal: true,
  },
  {
    icon: <Icon icon={'tabler:api'} color={'#07ABA0'} />,
    name: 'Api Docs',
    link: 'https://apis.surfinn.kr/Women-Health-Platform/apis',
    horizontal: true,
  },
  {
    icon: <Icon icon={'teenyicons:git-outline'} color={'#F1892D'} />,
    name: 'GitLab',
    link: 'http://192.168.10.10:50080',
    horizontal: true,
  },
  {
    icon: <Icon icon={'file-icons:jenkins'} color={'#7E349D'} />,
    name: 'Jenkins',
    link: 'https://jenkins.surfinn.kr',
    horizontal: false,
  },
];
