import { useCallback, useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { AIResponse } from 'src/@types/apiResponseTypes';
import { useStores } from 'src/models';

import ChatSidebar from './components/chat-sidebar';
import DrawerForMobile from './components/chat-sidebar/DrawerForMobile';
import SidebarForDesktop from './components/chat-sidebar/SidebarForDesktop';
import ChatWindow from './components/chat-window';
import NewChatButton from './components/NewChatButton';
import useChatResponse from './hooks/useChatResponse';
import useFAQResponse from './hooks/useFAQResponse';
import useToggleDrawer from './hooks/useToggleDrawer';
import { isMobile } from './utils/common';
import { AI_RESPONSE_DEFAULT_VALUE, ConversationType } from './utils/types';

function AiChatbot() {
  const { authStore } = useStores();

  const userSid = authStore.user?.userSid;

  const [isChatWindowOpened, setIsChatWindowOpened] = useState(false);
  const [currentChatRoomId, setCurrentChatRoomId] = useState<string>('');
  const [aiResponse, setAiResponse] = useState<AIResponse>(AI_RESPONSE_DEFAULT_VALUE);
  const [aiResponseStatus, setAiResponseStatus] = useState<{
    isAITyping: boolean;
    error: string | null;
  }>({ isAITyping: false, error: null });

  const [isStreaming, setIsStreaming] = useState(false);

  const toggleDrawer = useToggleDrawer();

  const { faqs, findFAQ, faq, setFaq } = useFAQResponse({});

  const { list, history, conv, onDeleteChatRoom } = useChatResponse();
  const { chatList, getChatList } = list;
  const { findChatHistory } = history;
  const { conversations: myConv, setConversations: setMyConversations } = conv;
  const { deleteChat, deleteAllChats } = onDeleteChatRoom;

  const chatWindowOpeningCSS = isChatWindowOpened
    ? { display: 'flex', flexDirection: 'row' }
    : { display: 'none' };

  // 새로운 ChatRoom 추가 버튼의 이벤트 함수
  /** 새로운 ChatRoom 추가 시 1. 채팅방 List에 새로운 채팅방이 추가되지 않고 2. 새로운 채팅창만 열기 */
  const addNewChatRoom = useCallback(() => {
    // 현재 활성화된 채팅방 ID를 초기화 시켜서 다른 채팅방에 AI 메시지가 남는 것을 막는다.
    setCurrentChatRoomId('');
    setMyConversations([]);
  }, [setMyConversations]);

  const sendMessageToAI = useCallback(
    async (user_input: string) => {
      const userPayload =
        currentChatRoomId.length === 0
          ? { user_id: userSid ? userSid : 0, user_input }
          : { user_id: userSid ? userSid : 0, user_input, chat_id: currentChatRoomId };

      const newConversation: ConversationType = {
        userMessage: userPayload,
        aiResponse: {
          response: '',
          chat_id: '',
          user_id: 0,
          ord: 0,
          reg_dt: '',
        },
      };
      // AI메시지와 유저 메시지가 계속 이어지게 함.
      setMyConversations((prev) => {
        if (prev && prev.length > 0) {
          return [...(prev as ConversationType[]), newConversation];
        } else {
          return [newConversation];
        }
      });

      try {
        const url = `/v1/chatbot/chats/response`;

        setAiResponseStatus((prev) => ({ ...prev, isAITyping: true }));
        const response = await fetch(process.env.REACT_APP_CHATBOT_URL + url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userPayload),
        });

        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        let done = false;

        if (reader) {
          while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            setAiResponseStatus((prev) => ({ ...prev, isAITyping: false }));
            setIsStreaming(true);

            if (value) {
              // 여기서 chunk는 백엔드로부터 받은 데이터 조각
              const chunk = decoder.decode(value, { stream: true });
              const lines = chunk.split('\n');
              for (const line of lines) {
                if (line.startsWith('data: ')) {
                  try {
                    const parsedChunk = JSON.parse(line.slice(5)) as AIResponse;
                    setAiResponse(parsedChunk);

                    if (parsedChunk.complete) {
                      const { chat_id, chat_title, response, complete, user_id, ord, reg_dt } =
                        parsedChunk;
                      if (userSid) {
                        await getChatList({ user_id: userSid });

                        setCurrentChatRoomId(parsedChunk.chat_id);

                        setMyConversations((prev) =>
                          prev?.map((conv, index: number) =>
                            index === prev.length - 1
                              ? {
                                  ...conv,
                                  aiResponse: {
                                    user_id,
                                    chat_title,
                                    response,
                                    chat_id,
                                    ord,
                                    reg_dt,
                                    complete,
                                  },
                                }
                              : conv,
                          ),
                        );
                        // AI 메시지를 empty 문자로 초기화 시켜서 텍스트가 깜빡이는 사이드 이펙트 제거
                        setAiResponse(AI_RESPONSE_DEFAULT_VALUE);
                      }
                    }
                  } catch (error) {
                    console.error('JSON Parsing error - ', error);
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching stream data: ', error);
      } finally {
        setAiResponseStatus((prev) => ({ ...prev, isAITyping: false }));
        setIsStreaming(false);
      }
    },
    [currentChatRoomId, getChatList, setMyConversations, userSid],
  );

  const sanitizeChatRoomState = () => {
    setCurrentChatRoomId('');
    setMyConversations([]);
  };

  const deleteSingleChatRoom = async (id: string) => {
    try {
      if (userSid) {
        await deleteChat({ chat_id: id });
        chatList.length > 0 && (await getChatList({ user_id: userSid }));
        // 지운 채팅방 아이디 값을 초기값으로 초기화
        sanitizeChatRoomState();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAllChatRooms = async () => {
    try {
      if (userSid) {
        await deleteAllChats(userSid);
        chatList.length > 0 && (await getChatList({ user_id: userSid }));
        sanitizeChatRoomState();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSetCurrentRoomId = {
    onSetCurrentChatRoomId: async (chat_id: string) => {
      try {
        if (currentChatRoomId !== chat_id) {
          await findChatHistory({ chat_id });
          setCurrentChatRoomId(chat_id);
          setFaq(undefined);
        }
      } catch (error) {
        console.error(error);
      }
    },
    onSetCurrentFAQRoomId: async (faq_id: number) => {
      try {
        if (currentChatRoomId !== faq_id.toString()) {
          await findFAQ(faq_id);
          setCurrentChatRoomId(faq_id.toString());
          setMyConversations(undefined);
        }
      } catch (error) {
        console.error(error);
      }
    },
  };

  useEffect(() => {
    if (userSid) {
      getChatList({ user_id: userSid });
    }
  }, [chatList.length, userSid]);

  const handleCustomEvent = useCallback(() => {
    setIsChatWindowOpened(true);
  }, []);

  useEffect(() => {
    window.addEventListener('chatWindowCloseEvent', handleCustomEvent as EventListener);

    return () => {
      window.removeEventListener('chatWindowCloseEvent', handleCustomEvent as EventListener);
    };
  }, [handleCustomEvent]);

  const isAiProcessing = aiResponseStatus.isAITyping || isStreaming;

  const Sidebar = () => (
    <ChatSidebar
      chatRoomsCollection={{
        chatRooms: chatList,
        innerwaveGuideChatRooms: faqs,
      }}
      currentChatRoomIdState={{
        currentChatRoomId,
        onSetCurrentRoomId,
      }}
      onDeleteChatRoom={{
        onDeleteSingleChatRoom: deleteSingleChatRoom,
        onDeleteAllChatRooms: deleteAllChatRooms,
      }}
    />
  );

  return (
    <StyledContainer sx={chatWindowOpeningCSS}>
      {isMobile() ? (
        <DrawerForMobile
          toggleDrawer={toggleDrawer}
          isAiProcessing={isAiProcessing}
          onAddNewChatRoom={addNewChatRoom}
        >
          <Sidebar />
        </DrawerForMobile>
      ) : (
        <SidebarForDesktop
          newButtonComp={<NewChatButton disabled={isAiProcessing} onClick={addNewChatRoom} />}
        >
          <Sidebar />
        </SidebarForDesktop>
      )}
      <ChatWindow
        isStreaming={isStreaming}
        aiResponse={aiResponse}
        aiResponseStatus={aiResponseStatus}
        chatRoom={myConv || faq || []}
        currentChatRoomId={currentChatRoomId}
        onAddNewChatRoom={addNewChatRoom}
        onSendMessageToAI={sendMessageToAI}
        onClose={() => setIsChatWindowOpened(false)}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  '@media (min-width: 600px)': {
    maxWidth: '800px',
  },
  boxShadow: '0px 0px 16px #0000005a',
  height: '100%',
  zIndex: 3000,
}));

export default observer(AiChatbot);
