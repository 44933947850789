import { ElementType, lazy, Suspense } from 'react';

// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const Page403 = Loadable(lazy(() => import('../screens/common/Page403')));
export const Page404 = Loadable(lazy(() => import('../screens/common/Page404')));
export const Page500 = Loadable(lazy(() => import('../screens/common/Page500')));
export const ComingSoon = Loadable(lazy(() => import('../screens/common/ComingSoon')));
export const Maintenance = Loadable(lazy(() => import('../screens/common/Maintenance')));
export const Home = Loadable(lazy(() => import('../screens/home/Home')));
export const Login = Loadable(lazy(() => import('../screens/auth/login/Login')));
export const ResetPassword = Loadable(
  lazy(() => import('../screens/auth/reset-password/ResetPassword')),
);

// Management
export const OrganizationChart = Loadable(
  lazy(() => import('../screens/management/organization/organization-chart/OrganizationChart')),
);
export const OrganizationLayout = Loadable(
  lazy(() => import('../screens/management/organization/organization-layout/OrganizationLayout')),
);
export const ReportManagement = Loadable(
  lazy(() => import('../screens/management/report/report-management/ReportManagement')),
);
export const TeamReportManagement = Loadable(
  lazy(() => import('../screens/management/report/team-report-management/TeamReportManagement')),
);
export const HeadquarterReportManagement = Loadable(
  lazy(
    () =>
      import(
        '../screens/management/report/headquarter-report-management/HeadquarterReportManagement'
      ),
  ),
);
export const TeamMemberReportManagement = Loadable(
  lazy(
    () =>
      import(
        '../screens/management/report/team-member-report-management/TeamMemberReportManagement'
      ),
  ),
);
export const MeetingManagement = Loadable(
  lazy(() => import('../screens/management/report/meeting-management/MeetingManagement')),
);
export const ReportTemplate = Loadable(
  lazy(() => import('../screens/management/report/report-template/ReportTemplate')),
);
export const ReportAttachment = Loadable(
  lazy(() => import('../screens/management/report/report-attachment/ReportAttachment')),
);
export const CreateReport = Loadable(
  lazy(() => import('../screens/management/report/create-report/CreateReport')),
);
export const EditReport = Loadable(
  lazy(() => import('../screens/management/report/edit-report/EditReport')),
);

export const EquipmentStatistics = Loadable(
  lazy(() => import('../screens/management/inventory/equipment-statistics/EquipmentStatistics')),
);
export const EquipmentManagement = Loadable(
  lazy(() => import('../screens/management/inventory/equipment/EquipmentManagement')),
);
export const ServerManagement = Loadable(
  lazy(() => import('../screens/management/inventory/server/ServerManagement')),
);
export const VmManagement = Loadable(
  lazy(() => import('../screens/management/inventory/vm/VmManagement')),
);

// Admin Management
export const MenuManagement = Loadable(
  lazy(() => import('../screens/admin-management/menu-management/MenuManagement')),
);
export const GroupManagement = Loadable(
  lazy(() => import('../screens/admin-management/group-management/GroupManagement')),
);
export const EmployeeManagement = Loadable(
  lazy(() => import('../screens/admin-management/employee-management/EmployeeManagement')),
);
export const DeptManagement = Loadable(
  lazy(() => import('../screens/admin-management/dept-management/DeptManagement')),
);
export const ClientManagement = Loadable(
  lazy(() => import('../screens/admin-management/client-management/ClientManagement')),
);
export const ProjectManagement = Loadable(
  lazy(() => import('../screens/admin-management/project-management/ProjectManagement')),
);
export const ProjectStatistics = Loadable(
  lazy(() => import('../screens/admin-management/project-statistics/ProjectStatistics')),
);
export const ChatbotFAQManagement = Loadable(
  lazy(() => import('../screens/admin-management/chat-bot/ChatbotFAQManagement')),
);
